/* global chrome */

import produce from 'immer';
import { SERVER_ROOT, CLIENT_ROOT, extensionId, googleAdIds } from 'env';

const reducer = (state = { userLoaded: false, root: SERVER_ROOT, CLIENT_ROOT, isChrome: false, extensionInstalled: false, extensionId, appType: 'WEB', googleAdIds: [
  '8301814549',
  '4277442070',
  '2879765779',
  '8650171047',
  '7808696819',
] }, action) => produce(state, draftState => {
	switch (action.type){
		case 'CHECK_CHROME_EXTENSION':
		    chrome.runtime.sendMessage(extensionId, {type: 'version'},
		        function(response){
		        	console.log('response is', response)
		     	    if (response.success){
		     	    	console.log('extension installed');
	    				draftState.extensionInstalled = true;
		          	} else {
	    				console.log('extension not installed');
	    				draftState.extensionInstalled = false;
			        }
		      	}
		    );
		    break;

		case 'EXTENSION_INSTALLED':
			draftState.extensionInstalled = true;
			break;

		case 'EXTENSION_NOT_INSTALLED':
			draftState.extensionInstalled = false;
			break;

		case 'IS_CHROME':
			draftState.isChrome = true;
			break;

		case 'SET_USER_$':
			draftState.userLoaded = true;

		return draftState;
	}
})

export default reducer;

