import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';

import Icon from '@mui/material/Icon';

import { red, grey } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';

import GoogleButtonIcon from './googleButton.png';

//import { CSSTransitionGroup } from 'react-transition-group';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'

import ExtensionButton from 'components/extensionButton';

const SignIn = props => {

	const [content, setContent] = React.useState('loading');
	const [open, setOpen] = React.useState(true);
  const [isHowOpen, setIsHowOpen] = React.useState(false);
	const [loadedTime, setLoadedTime] = React.useState(null);
	const { root, CLIENT_ROOT } = useSelector(state => state.api);
	const sign = useSelector(state => state.sign);

	const [key, setKey] = React.useState('a');
	const [Loaded, setLoaded] = React.useState(false);

  const location = useLocation();
  const url = (location.pathname+location.search).substr(1)
	const user = useSelector(state => state.user);

  const extra = React.useRef(null)

  const dispatch = useDispatch();
	//const url_string = props.match.params.url;
  let navigate = useNavigate();

  const signIn = name => {
    const root = process.env.NODE_ENV === 'development'? 'http://localhost:8080':'https://api.socialbrowse.us'; 
    window.location.href = `${root}/auth/${name}?url=${encodeURIComponent(window.location.href)}`;
  };

	return (
      <>
        <div>
          <span>
              <img onClick={()=>signIn('google')} style={{width: '300px', cursor: 'pointer'}} src={GoogleButtonIcon}/>
            {
              /*
                <span style={{textAlign: 'left', fontSize: '15px'}}>Please sign in with</span>
                <Button onClick={()=>signIn('google')}>
                  <img style={{width: '30px', height: '30px'}} src={GoogleIcon}/>
                </Button>
                <Button onClick={()=>signIn('twitter')}>
                  <img style={{width: '30px', height: '30px'}} src={TwitterIcon}/>
                </Button>
                <Button onClick={()=>signIn('facebook')}>
                  <img style={{ width: '30px', height: '30px'}} src={FacebookIcon}/>
                </Button>
              */
            }
          </span>
        </div>
      </>
    );
}

export default SignIn;